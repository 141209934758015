<template>
  <div class="col-12">
    <div class="card card-body">
      <div class="col-12 table-responsive">
        <h4 v-if="loading">جاري التحميل...</h4>
        <table class="table table-hover table-bordered">
          <thead>
            <th>التاريخ</th>
            <th>النوع</th>
            <th>عرض</th>
          </thead>
          <tbody>
            <tr v-for="a in arr" :key="a._id">
              <td>
                {{ a.date }}
              </td>
              <td>
                {{
                  a.type
                    .replace("1", "كل فصل في لجنة")
                    .replace("2", "توزيع تسلسلي (توزيع كل صف في عامود)")
                    .replace("3", "توزيع ديناميكي")
                }}
              </td>
              <td class="text-right">
                <button
                  class="btn btn-block btn-relief-success"
                  @click="$router.push('/_committees/reports#' + a._id)"
                >
                  <i class="fa fa-eye"></i>
                  عرض
                  التقرير
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

var user = JSON.parse(localStorage.getItem("user"))
export default {
  data() {
    return {
      loading: true,
      arr: [],
    };
  },
  created() {
    if(!checkPer("committees")){
        this.$router.push('/NotPermitted')
        return false;
    }
    var g = this;
    $.post(api + "/user/committees/history", {
      jwt: user.jwt,
    })
      .then(function (a) {
        g.arr = a.response;
        g.loading = false;
      })
      .catch(function (a) {
        alert("حدث خطأ");
        g.loading = false;
      });
  },
  methods: {
  },
};
</script>

<style>
</style>